
::v-deep .el-tabs__header {
    background: #F3F3F6;
}

::v-deep .el-button--primary {
    background: #4D5AFF;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
}

.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    background-color: #ffffff;
    margin-top: 75px;
    position: relative;

    .step-box {
        position: absolute;
        width: 100%;
        height: 55px;
        top: -75px;
        display: flex;

        .step-box-contain {
            position: relative;

            .show-box {
                position: absolute;
                top: 16px;
                left: 168px;
                display: flex;

                .num-box {
                    width: 22px;
                    height: 22px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    color: #20C997;
                    font-size: 18px;
                    line-height: 23px;
                    text-align: center;
                    margin-top: 2px;
                }

                .text-box {
                    margin-left: 20px;
                    font-size: 18px;
                    color: #ffffff;
                }
            }
        }

    }

    .head-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 10px 30px 0 20px;

        .title {
            margin-top: 10px;
        }
    }

    .divide {
        padding: 0 20px;
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .table-box {
        padding-bottom: 50px;
    }

    .imggeshi {
        position: absolute;
        left: -88px;
        top: 24px;
        color: #999999;
        font-size: 12px;
    }

    .imggeshi1 {
        position: absolute;
        left: -88px;
        top: 42px;
        color: #999999;
        font-size: 12px;
    }

    .upload-box {
        display: flex;

        .upload-main {
            width: 358px;
            height: 206px;
            cursor: pointer;
            background: #F1F1F1;
            color: #4D5AFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            i {
                font-size: 36px;
            }

            span {
                color: #333333;
            }
        }

        .showImg-box {
            margin-left: 20px;

            &:nth-child(1) {
                margin-left: 0px;
            }

            .logo-box {
                width: 100px;
                height: 100px;
                border: 1px solid #EEEEEE;
                border-radius: 2px;
            }

            .qa-box {
                max-width: 154px;
                max-height: 212px;
            }
        }

        .img-box {
            margin-left: 24px;
            position: relative;
        }
    }

    .stu-box {
        flex: 1;
    }

    .footer-box {
        width: 100%;
        justify-content: center;
        display: flex;
        padding-bottom: 20px;

        ::v-deep .el-button {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
